'use strict';

(function ($, FUNCTIONS) {
	var a = 'is-active';
	var v = 'is-visible';
	var slideSpeed = '400';

	// ＜ルート設定対応＞要素が空だったら削除
	var removeElmIfEmpty = function removeElmIfEmpty() {
		$('.js-checkEmptyElm').each(function (idx, val) {
			if (!$(val).text().length) {
				$(val).remove();
			}
		});
	};
	// ＜ルート設定対応＞該当のリストにルート設定出力が0件の場合、指定する親ブロックごと削除する
	var removeBlockIfListEmpty = function removeBlockIfListEmpty() {
		if ($('.js-checkEmptyList').length) {
			$('.js-checkEmptyList').each(function (idx, val) {
				if (!$(val).find('li').length) {
					$(val).parents('.js-checkEmptyList-wrap').remove();
				}
			});
		}
		if ($('.js-checkEmptyList2').length) {
			$('.js-checkEmptyList2').each(function (idx, val) {
				if (!$(val).find('li').length) {
					if (!$(val).find('img').length) {
						$(val).parents('.js-checkEmptyList2-wrap').remove();
					}
				}
			});
		}
	};
	var jsFileSize = function jsFileSize() {
		if ($('.js-fileSize').length) {
			$('.js-fileSize').each(function () {
				$(this).text('［' + bytesToSize($(this).text()) + '］');
			});
		}
		function bytesToSize(bytes) {
			var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
			if (bytes === 0) {
				return 'n/a';
			}
			var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
			if (i === 0) {
				return bytes + ' ' + sizes[i];
			}
			return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
		}
	};
	var slide = function slide() {
		if ($('.mod-slide01').length) {
			$('.mod-slide01').slick({
				infinite: true,
				speed: 1000,
				autoplay: true,
				autoplaySpeed: 4000,
				slidesToShow: 1,
				arrows: false,
				dots: true
			});
		}
	};
	var tabChange = function tabChange() {
		if ($('.js-tabChange-content').length) {
			$('.js-tabChange-content').eq(0).addClass(a);
			$('.js-tabChange-btn').eq(0).addClass(a);
			var $hash = location.hash;
			$('.js-tabChange-content').removeClass(a);
			$('.js-tabChange-btn').removeClass(a);
			if ($hash.length) {
				if ($hash.replace('#', '') === 'machinery') {
					$('.js-tabChange-content.laboratory-tab-content').eq(0).addClass(a);
					$('.js-tabChange-btn.laboratory-tab').eq(0).addClass(a);
				} else if ($hash.replace('#', '') === 'excipients') {
					$('.js-tabChange-content.laboratory-tab-content').eq(1).addClass(a);
					$('.js-tabChange-btn.laboratory-tab').eq(1).addClass(a);
				} else if ($hash.replace('#', '') === 'preservative') {
					$('.js-tabChange-content.laboratory-tab-content').eq(2).addClass(a);
					$('.js-tabChange-btn.laboratory-tab').eq(2).addClass(a);
				}
			} else {
				$('.js-tabChange-content').eq(0).addClass(a);
				$('.js-tabChange-btn').eq(0).addClass(a);
			}
			$('.js-tabChange-btn').each(function () {
				$(this).on('click', function () {
					var index = $('.js-tabChange-btn').index(this);
					$('.js-tabChange-btn').removeClass(a);
					$(this).addClass(a);
					$('.js-tabChange-content').removeClass(a);
					$('.js-tabChange-content').eq(index).addClass(a);
				});
			});
		}
	};
	var animation = function animation() {
		if ($('.js-inview-bottom').length) {
			$('.js-inview-bottom').waypoint(function (direction) {
				var activePoint = $(this.element);
				if (direction === 'down') {
					// scroll down
					activePoint.addClass(a);
				} else {
					// scroll up
					activePoint.removeClass(a);
				}
			}, { offset: '70%' });
		}
		if ($('.js-inview-left').length) {
			$('.js-inview-left').waypoint(function (direction) {
				var activePoint = $(this.element);
				if (direction === 'down') {
					// scroll down
					activePoint.addClass(a);
				} else {
					// scroll up
					activePoint.removeClass(a);
				}
			}, { offset: '70%' });
		}
		if ($('.js-inview-right').length) {
			$('.js-inview-right').waypoint(function (direction) {
				var activePoint = $(this.element);
				if (direction === 'down') {
					// scroll down
					activePoint.addClass(a);
				} else {
					// scroll up
					activePoint.removeClass(a);
				}
			}, { offset: '70%' });
		}
	};
	var floatBnr = function floatBnr() {
		if (!(location.pathname.split('/')[1] === 'english')) {
			if (location.pathname.indexOf('/product/preservation/') != -1) {
				var img = '<img src="/images/product/preservation/floatBnr.jpg" class="">';
				if (location.pathname.split('/')[1] === 'english') {
					img = '<img src="/images/product/preservation/floatBnr_en.jpg" class="">';
				}
				var HTML = '<div class="product-preservation-floatBnr opacity">' + '<a href="https://hinho-com.myshopify.com/" target="_blank" class="__bnr">' + img + '</a>' + '<button type="button" class="__close"><span></span></button>' + '</div>';
				var cookieIf = document.cookie.indexOf('productPreservationFloatBnrCookieData');
				if (cookieIf == -1) {
					$('.contents').append(HTML);
				}
				$(document).on('click', '.product-preservation-floatBnr .__close', function (event) {
					$('.product-preservation-floatBnr').addClass('di_none');
					var now = new Date();
					now.setDate(now.getDate() + 90);
					document.cookie = 'productPreservationFloatBnrCookieData=ok;expires=' + now.toUTCString() + ';path=/;';
				});
			}
		}
	};
	var newsLinkSetting = function newsLinkSetting() {
		if ($('.js-news-wrap').length) {
			for (var i = 0; i < $('.js-news-wrap').length; i++) {
				var year = $('.js-news-wrap').eq(i).find('.js-news-data').text().split('.')[0];
				// console.log(year);
				var link = '/news/' + year + '/';
				if ($('.js-news-wrap').eq(i).find('.rt_bn_event_news_lastest').length) {
					link = '/news/' + year + '/event/';
				}
				$('.js-news-wrap').eq(i).find('.js-news-link').attr('href', link);
			}
		}
	};
	removeElmIfEmpty();
	removeBlockIfListEmpty();
	jsFileSize();
	slide();
	tabChange();
	animation();
	floatBnr();
	newsLinkSetting();
})(window.jQuery, window.FUNCTIONS);