'use strict';

window.console || (window.console = {
	log: function log() {}
});
var FUNCTIONS = window.FUNCTIONS || null;
var cssua = window.cssua || null;

(function ($) {
	var siteName = 'freund';
	var a = 'is-active';
	var v = 'is-visible';
	var f = 'is-fixed';
	var slideSpeed = '400';

	FUNCTIONS = {
		va: {
			pathname: location.pathname,
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		localDecision: function localDecision() {
			var regExp = new RegExp('(test\\.' + siteName + '\\.connecty\\.co\\.jp|copre\\.jp|localhost|192\\.168\\.0\\.|192\\.168\\.11\\.|192\\.168\\.1\\.)', 'g');
			return regExp.test(location.hostname);
		},
		localLoading: function localLoading() {
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var i, j;
			var includeClass = document.querySelectorAll('[class*="' + key + '"]');
			var includeClassLen = includeClass.length;

			for (i = 0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if (ajaxLen > 0) {
				for (i = 0; i < ajaxLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: ajaxArray[i] + '.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done(function () {
					for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
						args[_key] = arguments[_key];
					}

					var regExp = new RegExp(key);

					for (i = 0; i < args.length; i++) {
						var result = args[i];
						var position = $(result[0]).filter(':first').attr('class').split(' ');

						for (j = 0; j < position.length; j++) {
							if (position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.' + position).html(result[0]).children().unwrap();

						console.log('Succeeded to read the include file!:', position);
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		catchAjaxError: function catchAjaxError(_errorThrown) {
			if (window.ga) {
				window.ga('send', 'event', 'ajaxError', location.href, _errorThrown, 0, true);
			}
		},
		getQuery: function getQuery() {
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for (var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},
		nextToggle: function nextToggle() {
			var $switch = $('.js-toggle');

			$switch.on('click', function () {
				$(this).toggleClass(a);
				$(this).next('.js-toggleBlock').stop().slideToggle(slideSpeed);
			});
		},
		easeScroll: function easeScroll() {
			$('a[rel="scroll"]').on('click', function (event) {
				var speed = 1200;
				if (/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href, speed);
				}
				return false;
			});
			function scrollToObject(_self, _speed) {
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				if (hash !== '#pagetop') {
					if (FUNCTIONS.va.window.width < FUNCTIONS.va.device.sp) {
						if (!(location.pathname === '/english/laboratory/globallabservice.html')) {
							pos -= 60;
						} else {
							pos -= 50;
						}
					} else {
						if (!(location.pathname === '/english/laboratory/globallabservice.html')) {
							pos -= 90;
						} else {
							pos -= 80;
						}
					}
				}

				$('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
			}
		},
		pageTop: function pageTop() {
			var $pagetop = $('.footer-pagetop');

			$(window).scroll(function (e) {
				var $this = $(e.currentTarget);
				var $footer = $('.footer');
				var footerPos = $footer.offset().top - $('.footer').height() - $(window).height();
				var thisScroll = $this.scrollTop();
				// console.log(footerPos,thisScroll);
				if (thisScroll > footerPos) {
					$pagetop.addClass('is-absolute');
				} else {
					$pagetop.removeClass('is-absolute');
				}
				if (thisScroll > '150') {
					$pagetop.addClass(v);
				} else {
					$pagetop.removeClass(v);
				}
			});
		},
		headerMenu: function headerMenu() {
			var $menu = $('.js-header-menu');
			var $menucontent = $('.js-header-menu-content');
			var flag = false;

			// ハンバーガーメニュー
			$menu.on('click', function () {
				if (!flag) {
					// scrollTopNow = document.body.scrollTop + document.documentElement.scrollTop;
					$menu.addClass(a);
					$menucontent.stop().slideDown(400);
					flag = true;
				} else {
					$menu.removeClass(a);
					$menucontent.stop().slideUp(400);
					flag = false;
				}
			});
		},
		closeHeaderMenu: function closeHeaderMenu() {
			var $menu = $('.js-header-menu');
			var $menucontent = $('.js-header-menu-content');

			window.addEventListener('resize', function () {
				$menu.removeClass(a);
				$menucontent.stop().slideUp(400);
			});
		},
		headerMegaMene: function headerMegaMene() {
			var $btn = $('.header-nav-content-li-in.__items');
			var $item = $('.header-nav-content-li_menu');
			var $nav = $('.header-nav-content');

			if (!(FUNCTIONS.va.window.width < 1024)) {
				$btn.on('mouseenter', function () {
					$btn.removeClass(v);
					$item.stop().slideUp(400);
					if (location.pathname.split('/')[1] === 'english') {
						setTimeout(function () {
							$item.css('height', 'auto');
						}, 400);
					}
					$(this).addClass(v);
					$(this).next($item).stop().slideDown(400);
				});
				$nav.on('mouseleave', function () {
					$btn.removeClass(v);
					$item.stop().slideUp(400);
					if (location.pathname.split('/')[1] === 'english') {
						setTimeout(function () {
							$item.css('height', 'auto');
						}, 400);
					}
				});
				// $item.on('mouseleave', function(){
				// 	$btn.removeClass(v);
				// 	$item.stop().slideUp(400);
				// });
				if ($('.js-header-menu-content').length) {
					$('.js-header-menu-content').eq(0).addClass(a);
					$('.js-header-menu-btn').eq(0).addClass(a);

					$('.js-header-menu-btn').each(function () {
						$(this).on('mouseenter', function () {
							var index = $('.js-header-menu-btn').index(this);
							$('.js-header-menu-btn').removeClass(a);
							$(this).addClass(a);
							$('.js-header-menu-content').removeClass(a);
							$('.js-header-menu-content').eq(index).addClass(a);
						});
					});
				}
			}
		},
		share: function share() {
			if ($('.mod-sns').length) {
				var pageUrl = document.URL;
				var shareUrl = "";
				var sharePagetitle = document.title;
				// facebook
				if ($('.mod-sns .__facebook').length) {
					shareUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + pageUrl;
					$('.mod-sns .__facebook').attr('href', shareUrl);
				}
				// twitter
				if ($('.mod-sns .__twitter').length) {
					shareUrl = 'https://twitter.com/share?text=' + sharePagetitle + '&url=' + pageUrl;
					$('.mod-sns .__twitter').attr('href', shareUrl);
				}
				// line
				if ($('.mod-sns .__line').length) {
					shareUrl = 'https://social-plugins.line.me/lineit/share?url=' + pageUrl;
					$('.mod-sns .__line').attr('href', shareUrl);
				}
				// mail
				if ($('.mod-sns .__mail').length) {
					shareUrl = 'mailto:?subject=' + sharePagetitle + '&body=' + pageUrl;
					$('.mod-sns .__mail').attr('href', shareUrl);
				}
			}
		},
		tagRemove: function tagRemove() {
			var tagRemoveJs = function tagRemoveJs(_item) {
				if (_item.length) {
					for (var i = 0; i < _item.length; i++) {
						var check = _item.eq(i).text().replace(/^[\s　\uFEFF\xA0]+|[\s　\uFEFF\xA0]+$/g, '');
						if (!check) {
							_item.eq(i).remove();
						}
					}
				}
			};
			var iconRemoveJs = function iconRemoveJs(_item) {
				if (_item.length) {
					for (var i = 0; i < _item.length; i++) {
						var check = _item.eq(i).find('.__tag').text().replace(/(^\s+)|(\s+$)/g, "").length;
						if (check) {
							_item.eq(i).addClass(a);
						}
					}
				}
			};
			tagRemoveJs($('.mod-box02-category .cf_ps_tags'));
			tagRemoveJs($('.mod-box02-use .cf_ps_tags'));
			tagRemoveJs($('.mod-box02-tag .cf_ps_tags'));
			tagRemoveJs($('.product-detail-iconWrap .cf_ps_tags'));
			iconRemoveJs($('.js-machinery-tab-content .mod-box02-icon'));
		},
		sideNav: function sideNav() {
			if ($('.js-nav-list').length) {
				var thisLink = void 0;
				// minHeadのカレント
				for (var i = 0; i < $('.js-nav-list .__minHead').length; i++) {
					thisLink = $('.js-nav-list .__minHead').eq(i).attr('href');
					if (location.pathname.indexOf(thisLink) != -1) {
						if ($('.js-nav-list .__minHead').eq(i).next('div')) {
							$('.js-nav-list .__minHead').eq(i).next('div').removeClass('di_none');
						}
					}
				}
				// リンクのカレント
				for (var _i = 0; _i < $('.js-nav-list a').length; _i++) {
					thisLink = $('.js-nav-list a').eq(_i).attr('href');
					if (location.pathname === $('.js-nav-list a').eq(_i).attr('href')) {
						$('.js-nav-list a').eq(_i).addClass(a);
					}
				}
			}
		},
		modal: function modal() {
			$('.mod-modal').on('click', function (e) {
				var HTML = '<div class="mod-modal-overlay">';
				HTML += '<div class="mod-modal-closeArea"></div>' + '<span class="mod-modal-closeBtn"><span></span></span>' + ($(e.currentTarget).find('img').prop('outerHTML') + '</div>');
				$('body').append(HTML);
				var $overlay = $('.mod-modal-overlay');
				$overlay.addClass(a);
				setTimeout(function () {
					$overlay.addClass(v);
					$('.mod-modal-closeArea, .mod-modal-closeBtn').on('click', function () {
						$overlay.removeClass(v);
						setTimeout(function () {
							$overlay.removeClass(a);
							$overlay.remove();
						}, 400);
					});
				});
			});
		},
		loadDelayScript: function loadDelayScript() {
			var _this = this;
			_this.nextToggle();
			_this.easeScroll();
			_this.pageTop();
			_this.headerMenu();
			_this.closeHeaderMenu();
			_this.headerMegaMene();
			_this.share();
			_this.tagRemove();
			_this.sideNav();
			_this.modal();
			svg4everybody();
		}
	};

	$(function () {
		return FUNCTIONS.localDecision() ? FUNCTIONS.localLoading() : FUNCTIONS.loadDelayScript();
	});
})(window.jQuery);
$(window).on('load', function () {
	var imgRemoveJs = function imgRemoveJs(_item) {
		for (var i = 0; i < _item.length; i++) {
			var check = _item.eq(i).find('img').eq(0).height();
			if (!check) {
				_item.eq(i).remove();
			}
		}
	};
	imgRemoveJs($('.product-detail-icon .__icon'));
	var a = 'is-active';
	var modtabChange = function modtabChange() {
		if ($('.mod-tab').length) {
			if ($('.mod-tab').eq(0).hasClass('js-tabChange')) {

				var $hash = location.hash;
				if ($hash.length) {
					var pos = $('.mod-tab-item').eq(0).offset().top;
					if ($(window).width() < 768) {
						pos -= 60;
					} else {
						pos -= 90;
					}
					$('html,body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
					if ($hash.replace('#', '') === '01') {
						$('.mod-tab-content').eq(0).addClass(a);
						$('.mod-tab').each(function (idx, ele) {
							$(ele).find('.mod-tab-item').eq(0).addClass(a);
						});
					} else if ($hash.replace('#', '') === '02') {
						$('.mod-tab-content').eq(1).addClass(a);
						$('.mod-tab').each(function (idx, ele) {
							$(ele).find('.mod-tab-item').eq(1).addClass(a);
						});
					} else if ($hash.replace('#', '') === '03') {
						$('.mod-tab-content').eq(2).addClass(a);
						$('.mod-tab').each(function (idx, ele) {
							$(ele).find('.mod-tab-item').eq(2).addClass(a);
						});
					} else if ($hash.replace('#', '') === '04') {
						$('.mod-tab-content').eq(3).addClass(a);
						$('.mod-tab').each(function (idx, ele) {
							$(ele).find('.mod-tab-item').eq(3).addClass(a);
						});
					}
				} else {
					$('.mod-tab-content').eq(0).addClass(a);
					$('.mod-tab').each(function (idx, ele) {
						$(ele).find('.mod-tab-item').eq(0).addClass(a);
					});
				}

				$('.mod-tab').each(function (idx, ele) {
					$(ele).find('.mod-tab-item').each(function (idx2, val) {
						$(val).on('click', function () {
							$('.mod-tab-item').removeClass(a);
							$('.mod-tab-content').removeClass(a);
							$('.mod-tab-content').eq(idx2).addClass(a);
							$('.mod-tab').each(function (idx3, ele2) {
								$(ele2).find('.mod-tab-item').eq(idx2).addClass(a);
							});
						});
					});
				});
			}
		}
	};
	var easeScrollHash = function easeScrollHash() {
		if (location.hash) {
			var hash = '#' + location.hash.split('#')[1];
			var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;
			if (hash !== '#pagetop') {
				if ($(window).width() < 1024) {
					if (!(location.pathname === '/english/laboratory/globallabservice.html')) {
						pos -= 60;
					} else {
						pos -= 50;
					}
				} else {
					if (!(location.pathname === '/english/laboratory/globallabservice.html')) {
						pos -= 90;
					} else {
						pos -= 80;
					}
				}
			}
			$('html,body').stop().animate({ scrollTop: pos }, 400, 'easeOutExpo');
		}
	};
	modtabChange();
	easeScrollHash();
});